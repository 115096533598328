import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Admin from "./pages/Admin";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Equipments from "./pages/Equipments";
import Register from "./pages/Register";
import ErrorPage from "./error-page";
import Loader from "./components/ui/Loader";
import "animate.css/animate.css";
import Header from "../src/components/ui/Header";
import Footer from "../src/components/ui/Footer";

// import Topbar from "../src/components/ui/TopBar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "/contact",
    element: <Contact navType="contact" />
  },
  {
    path: "/about",
    element: <AboutUs />
  },
  {
    path: "/equipments",
    element: <Equipments />
  },
  {
    path: "/register",
    element: <Register />
  },
  {
    path: "/dr_admin",
    element: <Admin />
  }
]);

function AppWrapper() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <React.StrictMode>
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <Topbar /> */}
          {/* <Header navType="contact" />
          <RouterProvider router={router} />
          <Footer /> */}
          <p>Site not available</p>
        </>
      )}
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppWrapper />);
