import jcb from "../../../../src/img/brands/JCB.png";
import xcmg from "../../../../src/img/brands/XCMG.png";
import sldg from "../../../../src/img/brands/SLDG.png";
import man from "../../../../src/img/brands/MAN.png";
import hino from "../../../../src/img/brands/HINO.png";
import iveco from "../../../../src/img/brands/IVECO.png";
import cat from "../../../../src/img/brands/CAT.png";
import hitachi from "../../../../src/img/brands/HITACHI.png";
import hyundai from "../../../../src/img/brands/HYUNDAI.png";
import bobcat from "../../../../src/img/brands/BOBCAT.png";
import mitsubishi from "../../../../src/img/brands/MITSUBISHI.png";
import volvo from "../../../../src/img/brands/VOLVO.png";
import komatsu from "../../../../src/img/brands/KOMATSU.png";
import doosan from "../../../../src/img/brands/DOOSAN.png";
import sany from "../../../../src/img/brands/SANY.png";

const brandLogos = [
  { name: "JCB", Image: jcb, invert: false, resize: false },
  { name: "XCMG", Image: xcmg, invert: false, resize: false },
  { name: "SLDG", Image: sldg, invert: false, resize: true },
  { name: "MAN", Image: man, invert: false, resize: false },
  { name: "HINO", Image: hino, invert: true, resize: false },
  { name: "IVECO", Image: iveco, invert: false, resize: false },
  { name: "CATERPILLAR", Image: cat, invert: false, resize: false },
  { name: "MITSUBISHI", Image: mitsubishi, invert: true, resize: false },
  { name: "HYUNDAI", Image: hyundai, invert: false, resize: false },
  { name: "BOBCAT", Image: bobcat, invert: false, resize: false },
  { name: "HITACHI", Image: hitachi, invert: false, resize: false },
  { name: "VOLVO", Image: volvo, invert: false, resize: false },
  { name: "KOMATSU", Image: komatsu, invert: false, resize: false },
  { name: "DOOSAN", Image: doosan, invert: false, resize: false },
  { name: "SANY", Image: sany, invert: false, resize: false }
];

export default function BrandsLogo() {
  return (
    <>
      <div className="py-80 lg:py-24 sm:py-32 bg-black">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-sm pb-9 font-semibold leading-8 text-white antialiased uppercase">
            Brands Available
          </h2>
          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            {brandLogos.map((item) => (
              <img
                key={item.name}
                draggable="false"
                className={
                  item.invert === true
                    ? "sm:ml-0 lg:ml-10 invert col-span-2 w-28 object-contain lg:col-span-1"
                    : "sm:ml-0 lg:ml-10 col-span-2 max-h-12 w-28 object-contain lg:col-span-1"
                }
                src={item.Image}
                alt="Sahari Kawther"
                width={50}
                height={50}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
