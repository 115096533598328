import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export default function Contact() {
  const [email, setEmail] = useState("info@saharikawther.com");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(phone + message)}`;
    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="bg-[url('https://images.unsplash.com/photo-1517089152318-42ec560349c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80')] bg-cover bg-center">
        <div className="mx-auto max-w-7xl  py-16 lg:py-64 px-6 sm:py-24 lg:px-8 ">
          <div className="text-left font-bold lg:text-center mt-20 lg:mt-0 text-white uppercase">
            <p>Contact</p>
            <p className=" mt-1 text-3xl font-bold tracking-tighter text-white  sm:text-5xl lg:text-8xl">
              SAHARI KAWTHER
            </p>
          </div>
        </div>
      </div>
      {/* {'imported'} */}
      <div className="relative bg-[#3c3c3f] p-0 lg:p-20">
        <div className="absolute inset-0">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-[#2a2a2d]" />
        </div>
        <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
          <div className="bg-[#eeeeee] py-16 px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <h2 className="text-2xl uppercase font-bold tracking-tight text-gray-900 sm:text-3xl">
                Get in touch
              </h2>
              <dl className="mt-8 text-base text-gray-500">
                <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>
                      Main Office <br />
                      <span>&nbsp;</span>- 186, PC:618, SULTHANATE OF OMAN
                    </p>
                    <hr className="border-gray-300 mb-2 mt-2" />
                    <p>
                      Branch Address [Main Operations] <br />{" "}
                      <span>&nbsp;</span>- Door No 31,3rd Floor Building No:
                      698, Block No:164, Way No: 5007 Al Azaiba South, Ghala,
                      Muscat, Sultanate of Oman
                    </p>
                  </dd>
                </div>
                <div className="mt-6">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex flex-col">
                    <PhoneIcon
                      className="h-6 w-6 mb-2 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3 font-semibold">+ 968-9222-0202</span>
                    <span className="ml-3 capitalize">
                      Said hamood humaid al durai [Chairman]
                    </span>
                    <span className="ml-3 mt-3 font-semibold tabular-nums">
                      + 968-9275-5509
                    </span>
                    <span className="ml-3 capitalize">
                      Mohammed Hamood Humaid al Durai [General Manager]
                    </span>
                    <span className="ml-3 mt-3 font-semibold">
                      + 968-9522-6850
                    </span>
                    <span className="ml-3 capitalize">
                      Nishar Mohamed [Business development manager ]
                    </span>
                    <span className="ml-3 mt-3 font-semibold">
                      + 968-9410-9930
                    </span>
                    <span className="ml-3 capitalize">
                      Jijo Johnson [Operation manager]
                    </span>
                    <span className="ml-3 mt-3 font-semibold">
                      + 968-9709-1411
                    </span>
                    <span className="ml-3 capitalize">Office</span>
                  </dd>
                </div>
                <div className="mt-5">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">info@saharikawther.com</span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="bg-[white] py-16 px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <form
                onSubmit={handleSubmit}
                method="POST"
                className="grid grid-cols-1 gap-y-6"
              >
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Subject
                  </label>
                  <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    name="Subject-name"
                    id="subject-name"
                    autoComplete="name"
                    className="block w-full   border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Subject"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full   border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    readOnly="true"
                    placeholder={email}
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    id="phone"
                    autoComplete="tel"
                    className="block w-full   border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Phone"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full   border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Message"
                    defaultValue={""}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="text-sm bg-[#fd6600] px-10 py-4 -mt-10 md:mt-0 lg:px-36 font-semibold leading-7 text-[#24242d] hover:text-white shadow-sm hover:bg-[#24242d] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 hover:ring-1 ring-[#fd6600] uppercase"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
