import "./App.css";
import Home from "./pages/Home";

function App() {
  return (
    <>
      {/* <Home /> */}
      <p>site not available</p>
    </>
  );
}

export default App;
