import React from "react";

const Loader = () => {
  return (
    <>
      <div className="flex h-[full] justify-center overflow-hidden overflow-y-hidden">
        <div className="flex-col mt-52">
          <lottie-player
            src="https://assets1.lottiefiles.com/packages/lf20_f70r9gaz.json"
            background="transparent"
            speed="1"
            style={{ width: 300 + "px", height: 300 + "px" }}
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>
    </>
  );
};

export default Loader;
