import React from "react";

function AboutUs() {
  const sisterConcerns = [
    {
      name: "Al kawther international business"
    },
    {
      name: "Talae al zaheya"
    },
    {
      name: "Al zaheya new business"
    },
    {
      name: "Al zaheya leading services"
    },
    {
      name: "Mashary alzaheya al mitwa"
    }
  ];
  return (
    <>
      <div className="bg-[url('https://images.unsplash.com/photo-1586161148512-64a1b3dac527?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1686&q=80')] bg-cover bg-center">
        <div className="mx-auto max-w-7xl  py-16 lg:py-64 px-6 sm:py-24 lg:px-8 ">
          <div className="text-left font-bold lg:text-center mt-20 lg:mt-0 text-white uppercase">
            <p>About</p>
            <p className=" mt-1 text-3xl font-bold tracking-tighter text-white  sm:text-5xl lg:text-8xl">
              SAHARI KAWTHER
            </p>
          </div>
        </div>
      </div>
      <div className="relative bg-WHITE text-white font-body antialiased">
        <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-32 ">
          <div className="px-6 mt-52 md:mt-0 pt-10 pb-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pt-48 lg:pb-56 xl:col-span-6">
            <div className="mx-auto max-w-2xl lg:mx-0 -mt-44 mb-24">
              <div className="hidden sm:mt-32 sm:flex lg:mt-16"></div>
              <h1 className="mt-0 font-body text-4xl font-black tracking-tight text-black sm:mt-10 sm:text-6xl">
                OUR STORY
              </h1>
              <p className="mt-10 text-sm leading-6 text-black antialiased">
                Sahari Kawther Trading Company LLC is a well-known name in the
                heavy machinery leasing industry, with operations in the
                Sultanate of Oman dating back to 2007. As a locally registered
                community contractor, we take great pride in providing
                top-quality, dependable heavy vehicles and earthmoving equipment
                to various companies throughout the region on a daily and
                monthly basis. We recognize the importance of maintaining the
                highest quality and safety standards, which is why all of our
                vehicles and equipment adhere to the most stringent
                PRO/OXY/OMAN/OIL specifications. Our dedication to excellence is
                unwavering, and we constantly strive to exceed our client's
                expectations through a combination of cutting-edge technology,
                industry expertise, and unrivalled customer service. We are
                committed to building strong, long-term relationships with our
                clients at Sahari Kawther Trading Company LLC. Transparency,
                integrity, and open communication, we believe, are essential for
                establishing trust and ensuring mutual success. Whether you're
                looking to lease heavy machinery for a specific project or need
                ongoing equipment rentals for your business, our team of highly
                skilled professionals is always available to provide
                personalized support and guidance. Choose Sahari Kawther Trading
                Company LLC for all your heavy machinery leasing needs, and
                experience the difference that comes with working with a company
                that puts your success first.
              </p>
              <div className="mt-10 flex items-center gap-x-6 absolute"></div>
            </div>
          </div>
          <div className="absolute  items-center justify-center lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
            <div className="relative z-10 flex align-middle flex-col md:mt-72 lg:mt-80 sm:mt-10 justify-center items-center">
              <img
                draggable="false"
                className=" aspect-[3/2] w-[400px] rounded-none md:rounded-sm mt-0 lg:mt-36 bg-transparent object-cover lg:absolute lg:aspect-auto lg:h-[550px]"
                src="https://images.unsplash.com/photo-1614562556758-ae0248358127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
                alt="Sahari Kawther"
              />
            </div>
          </div>
        </div>
        {/* next section  */}
        <div class="bg-white -mt-60 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <dl class="grid grid-cols-1 gap-y-16 gap-x-8 text-center lg:grid-cols-3">
              <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt class="text-base leading-7 text-gray-600">
                  Transactions every 24 hours
                </dt>
                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  44 million
                </dd>
              </div>

              <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt class="text-base leading-7 text-gray-600">
                  Assets under holding
                </dt>
                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  $119 trillion
                </dd>
              </div>

              <div class="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt class="text-base leading-7 text-gray-600">
                  New users annually
                </dt>
                <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  46,000
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="bg-[#2a2a2d] py-24 sm:py-32 ">
        <div class="mx-auto grid max-w-7xl mt-5 gap-y-20 gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
          <div class="max-w-2xl">
            <h2 class="text-3xl font-bold tracking-tight uppercase text-white sm:text-4xl">
              Meet our Sister Concerns
            </h2>
            <p class="mt-6 text-base leading-6 tracking-wide text-white">
              Discover the diverse range of our sister concerns, each
              specializing in their unique fields. From cutting-edge technology
              to sustainable development, our family of businesses is committed
              to excellence, innovation, and making a positive impact in the
              world.
            </p>
          </div>
          <ul class="ml-20 grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {sisterConcerns.map((item) => (
              <li>
                <div class="flex items-center gap-x-6">
                  <div>
                    <h3 class="text-lg tracking-wide font-semibold leading-7 text-white">
                      {item.name}
                    </h3>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
