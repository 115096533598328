import React, { useState, useEffect } from "react";
import { sha256 } from "js-sha256";
import * as XLSX from "xlsx";

function Admin() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [visible, setVisible] = useState(false);
  const FETCH_URL = process.env.REACT_APP_FETCH_URL;
  const PASSWORD = process.env.REACT_APP_PASSWORD;
  const SALT_ROUNDS = process.env.REACT_APP_SALT;
  const [password, setPassword] = useState("");

  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("passwordHash") === sha256(PASSWORD + SALT_ROUNDS)
  );
  //handling the password
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    const passwordHash = sha256(password + SALT_ROUNDS);
    if (passwordHash === localStorage.getItem("passwordHash")) {
      setIsAuthenticated(true);
      setVisible(false);
    } else {
      alert("Incorrect password. Please try again.");
      setVisible(false);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setVisible(false);
  };

  const savePasswordHash = () => {
    localStorage.setItem("passwordHash", sha256(PASSWORD + SALT_ROUNDS));
    setVisible(true);
  };

  useEffect(() => {
    fetch(FETCH_URL)
      .then((response) => response.json())
      .then((data) => {
        const entries = Object.entries(data);
        const result = entries.map(([id, entry]) => ({ id, ...entry }));
        setData(result);
        setSearchResults(result);
      })
      .catch((error) => console.error(error));
  }, [FETCH_URL]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = data.filter((item) => {
      return (
        item.companyName.toLowerCase().includes(query) ||
        item.email.toLowerCase().includes(query) ||
        item.phoneNumber.toLowerCase().includes(query)
      );
    });
    setSearchResults(filteredData);
  };

  const handleClearSearch = () => {
    setSearchResults(data);
    setSearchQuery("");
  };

  function handleExport() {
    const data = searchResults.map((item) => ({
      ID: item.id,
      "Company Name": item.companyName,
      "CR Cert": item.crCert,
      Email: item.email,
      "JSRF Membership": item.jsrfMembership,
      "Phone Number": item.phoneNumber,
      "VAT Cert": item.vatCert
    }));

    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");

    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const file = new Blob([wbout], { type: "application/vnd.ms-excel" });
    const fileName = "table.xlsx";

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(file);
    link.download = fileName;
    link.click();
  }

  return (
    <>
      {!isAuthenticated ? (
        <>
          <div className="flex flex-col justify-center items-center h-screen">
            <form className="bg-white shadow-md  px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="password"
                >
                  Enter password:
                </label>
                <input
                  className="shadow appearance-none border  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                />
              </div>
              <div className="flex items-center justify-between">
                {visible && (
                  <button
                    className="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                )}{" "}
                <button
                  className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4  focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={savePasswordHash}
                >
                  Save password hash
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col mt-36 items-center">
            <div className="w-2/3 flex justify-between items-center mb-4">
              <button
                className="inline-flex justify-center items-center px-4 py-2 border border-transparent text-base font-medium  text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                onClick={handleLogout}
              >
                <svg
                  className="w-5 h-5 mr-2"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.343 15.657a2 2 0 102.828-2.828L6.586 11h6.116l-.585.585a2 2 0 102.828 2.828l-3 3a2 2 0 01-2.828 0l-3-3zM10 18a8 8 0 110-16 8 8 0 010 16z"
                    clipRule="evenodd"
                  />
                </svg>
                Logout
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
                onClick={handleExport}
              >
                Export Table
              </button>

              <div className="flex flex-row justify-center mb-5 ">
                <input
                  type="text"
                  placeholder="Search..."
                  className="px-3 py-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                  value={searchQuery}
                  onChange={(event) =>
                    handleSearch(event.target.value.toLowerCase())
                  }
                />
                {searchQuery && (
                  <button
                    className=" top-1 right-1 ml-3 border p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                    onClick={handleClearSearch}
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>

            <table className="table-auto w-full max-w-4xl">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    SI
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Company Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    CR Cert
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    JSRF Membership
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Phone Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    VAT Cert
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((item, index) => (
                  <tr
                    key={item.id}
                    className={index % 2 === 0 ? "bg-gray-50" : ""}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.companyName}
                    </td>
                    <td className="border px-4 py-2">
                      {item.crCert &&
                      item.crCert.includes("data:application") ? (
                        <a
                          href={item.crCert}
                          download={`${item.companyName}-CR-Cert.pdf`}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          Download
                        </a>
                      ) : item.crCert.length > 4 ? (
                        item.crCert.slice(0, 3)
                      ) : (
                        item.crCert
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.jsrfMembership}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.phoneNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {item.vatCert &&
                      item.vatCert.includes("data:application") ? (
                        <a
                          href={item.vatCert}
                          download={`${item.companyName}-CR-Cert.pdf`}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          Download
                        </a>
                      ) : item.vatCert.length > 4 ? (
                        item.vatCert.slice(0, 3)
                      ) : (
                        item.vatCert
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default Admin;
