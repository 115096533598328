import React, { useState } from "react";

export default function Register() {
  const FETCH_URL = process.env.REACT_APP_FETCH_URL;
  const [userData, setUserData] = useState({
    companyName: "",
    fullName: "",
    vatCert: "",
    crCert: "",
    jsrfMembership: "",
    phoneNumber: "",
    email: ""
  });

  const postUserData = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "file" ? event.target.files[0] : event.target.value;
    if (event.target.type === "file") {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        setUserData({ ...userData, [name]: reader.result });
      };
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const submitData = (event) => {
    event.preventDefault();
    const {
      companyName,
      fullName,
      vatCert,
      crCert,
      jsrfMembership,
      phoneNumber,
      email
    } = userData;

    const res = fetch(FETCH_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        companyName,
        fullName,
        vatCert,
        crCert,
        jsrfMembership,
        phoneNumber,
        email
      })
    });
    if (res) {
      console.log("bef", res);
      setUserData({
        companyName: "",
        fullName: "",
        vatCert: "",
        crCert: "",
        jsrfMembership: "",
        phoneNumber: "",
        email: ""
      });
      alert("success");
      console.log(res);
    } else {
      alert("error");
    }
  };

  return (
    <>
      {" "}
      <div className="App" style={{ marginTop: 100 }}>
        <div className="relative bg-white -mt-28">
          <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
            <div className="px-6 pt-10 pb-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pt-48 lg:pb-56 xl:col-span-6">
              <form className="w-full max-w-lg mx-auto">
                <div className="mb-4">
                  <label
                    className="block text-gray-700 font-bold mb-2 text-left"
                    htmlFor="company_name"
                  >
                    Company Name
                  </label>
                  <input
                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id="company_name"
                    name="companyName"
                    type="text"
                    placeholder="Enter your company name"
                    value={userData.companyName}
                    onChange={postUserData}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 font-bold mb-2 text-left"
                    htmlFor="full_name"
                  >
                    Full Name
                  </label>
                  <input
                    className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id="full_name"
                    name="fullName"
                    type="text"
                    placeholder="Enter your Full Name"
                    value={userData.fullName}
                    onChange={postUserData}
                    required
                  />
                </div>
                <div className="flex mb-4">
                  <div className="w-1/2 mr-2">
                    <label
                      className="block text-gray-700 font-bold mb-2 text-left"
                      htmlFor="vat_cert"
                    >
                      VAT Certificate
                    </label>
                    <input
                      className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="vat_cert"
                      name="vatCert"
                      type="file"
                      accept=".pdf"
                      onChange={postUserData}
                      required
                    />
                  </div>
                  <div className="w-1/2 ml-2">
                    <label
                      className="block text-gray-700 font-bold mb-2 text-left"
                      htmlFor="cr_cert"
                    >
                      CR Certificate
                    </label>
                    <input
                      className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="cr_cert"
                      name="crCert"
                      type="file"
                      accept=".pdf"
                      onChange={postUserData}
                      required
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mt-5 font-bold mb-2 text-left"
                    htmlFor="jsrf_membership"
                  >
                    JSRF Membership
                  </label>
                  <div className="flex items-center">
                    <input
                      className="mr-2 leading-tight"
                      id="jsrf_membership"
                      name="jsrfMembership"
                      type="checkbox"
                      checked={userData.jsrfMembership}
                      onChange={postUserData}
                    />
                    <span className="text-sm">I have a JSRF membership</span>
                  </div>
                </div>
                <div className="flex mb-4">
                  <div className="w-1/2 mr-2">
                    <label
                      className="block text-gray-700 font-bold mb-2 text-left"
                      htmlFor="phone_number"
                    >
                      Phone Number
                    </label>
                    <input
                      className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="phone_number"
                      name="phoneNumber"
                      type="tel"
                      placeholder="Enter your phone number"
                      value={userData.phoneNumber}
                      onChange={postUserData}
                      required
                    />
                  </div>
                  <div className="w-1/2 ml-2">
                    <label
                      className="block text-gray-700 font-bold mb-2 text-left"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className="appearance-none bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="email"
                      name="email"
                      type="email"
                      value={userData.email}
                      onChange={postUserData}
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                </div>
                <div className="flex justify-start mt-10">
                  <button
                    className="bg-[#fd6600] px-10 py-3 mt-10 md:mt-0 lg:px-20 text-lg font-semibold leading-7 text-[#24242d] hover:text-white shadow-sm hover:bg-[#24242d] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 hover:ring-1 ring-[#fd6600] uppercase"
                    onClick={submitData}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
              <img
                className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
                src="https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
