import React from "react";
import TELEHANDLER from "../img/equipments/TELEHANDLER.jpeg";
import BOOMLOADER from "../img/equipments/BOOMLOADER.jpeg";
import BACKHOE_LOADER from "../img/equipments/BACKHOELOADER.webp";
import ROLLER from "../img/equipments/ROLLER.webp";
import HIAB from "../img/equipments/HIAB.jpeg";
import TIPPER from "../img/equipments/TIPPER.jpeg";
import TOOL_CARRIER from "../img/equipments/TOOLCARRIER.webp";
import WHEEL_LOADER from "../img/equipments/WHEELLOADER.jpeg";
import DIESEL_TANKER from "../img/equipments/DIESELTANKER.webp";
import BRACKISH_TANKER from "../img/equipments/BRACKISHTANKER.jpeg";
import SEWAGE_TANKER from "../img/equipments/SEWAGETANKER.jpeg";
import GRADER from "../img/equipments/GRADER.webp";
import DOZER from "../img/equipments/DOZER.jpeg";
import CANTER from "../img/equipments/CANTER.webp";
import STEER_SKID_LOADER from "../img/equipments/STEERSKIDLOADER.jpeg";
import FORKLIFT from "../img/equipments/FORKLIFT.jpeg";
import EXCAVATOR from "../img/equipments/EXCAVATOR.webp";
import LOWBED from "../img/equipments/LOWBED.jpeg";
import FLATBED from "../img/equipments/FLATBED.jpeg";
import SHOVEL from "../img/equipments/SHOVEL.jpeg";
import SKIP_LOADER from "../img/equipments/SKIPLOADER.jpeg";
import BrandsLogo from "../../src/components/sections/home/BrandsLogo";

function Equipments() {
  const products = [
    {
      id: 1,
      name: "Telehandler",
      href: "#",
      imageSrc: TELEHANDLER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 2,
      name: "Boomloader",
      href: "#",
      imageSrc: BOOMLOADER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 3,
      name: "Backhoe Loader",
      href: "#",
      imageSrc: BACKHOE_LOADER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 4,
      name: "Roller",
      href: "#",
      imageSrc: ROLLER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 5,
      name: "Hiab",
      href: "#",
      imageSrc: HIAB,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 6,
      name: "Tipper",
      href: "#",
      imageSrc: TIPPER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 7,
      name: "Tool Carrier",
      href: "#",
      imageSrc: TOOL_CARRIER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 8,
      name: "Wheel Loader",
      href: "#",
      imageSrc: WHEEL_LOADER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 9,
      name: "Diesel Tanker",
      href: "#",
      imageSrc: DIESEL_TANKER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 10,
      name: "Brackish Tanker",
      href: "#",
      imageSrc: BRACKISH_TANKER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 11,
      name: "Sewage Tanker",
      href: "#",
      imageSrc: SEWAGE_TANKER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 12,
      name: "Grader",
      href: "#",
      imageSrc: GRADER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 13,
      name: "Dozer",
      href: "#",
      imageSrc: DOZER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 14,
      name: "Canter",
      href: "#",
      imageSrc: CANTER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 15,
      name: "Steer Skid Loader",
      href: "#",
      imageSrc: STEER_SKID_LOADER,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 16,
      name: "Forklift",
      href: "#",
      imageSrc: FORKLIFT,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 17,
      name: "Excavator",
      href: "#",
      imageSrc: EXCAVATOR,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 18,
      name: "Lowbed",
      imageSrc: LOWBED,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 19,
      name: "Flatbed",
      imageSrc: FLATBED,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 20,
      name: "Shovel",
      imageSrc: SHOVEL,
      imageAlt: "Sahari Kawther"
    },
    {
      id: 21,
      name: "Skip Loader",
      imageSrc: SKIP_LOADER,
      imageAlt: "Sahari Kawther"
    }
  ];
  return (
    <>
      <div className="bg-[url('https://images.unsplash.com/photo-1583024011792-b165975b52f5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80')] bg-cover">
        <div className="mx-auto max-w-7xl  py-16 lg:py-64 px-6 sm:py-24 lg:px-8 ">
          <div className="text-left font-bold lg:text-center mt-20 lg:mt-0 text-white uppercase">
            <p className="text-orange-500">HELPING CONTRACTORS</p>
            <p className=" mt-1 text-3xl font-bold tracking-tighter text-white  sm:text-5xl lg:text-8xl">
              SAHARI KAWTHER
            </p>
          </div>
        </div>
      </div>

      <div className="bg-[#f9f9f6]">
        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          <p className="text-sm text-orange-600">FIND YOUR NEXT RENTAL</p>
          <h2 className="text-3xl mb-24 font-black tracking-tighter text-gray-900">
            EQUIPMENT RENTAL OPTIONS
          </h2>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {products.map((product) => (
              <div className="group relative">
                <div className="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-white group-hover:opacity-75 lg:aspect-none lg:h-80">
                  <img
                    src={product.imageSrc}
                    alt={product.imageAlt}
                    className="h-full w-full object-contain lg:h-full lg:w-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm -mt-1 text-black tracking-normal font-medium uppercase">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </h3>
                  </div>
                  {/* <p className="text-sm font-medium text-gray-900">$ 0.22</p> */}
                  <span className="h-4 w-4 bg-orange-600"></span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <BrandsLogo />
      </div>
    </>
  );
}

export default Equipments;
